import { GameBySlugRequestDto } from '@/services/dto/games/game-by-slug-request.dto';
import { GamesQueryKeys } from '../../queryKeys';
import { useQuery } from '@tanstack/react-query';
import { fetchGameBySLug } from './service';
import { Game } from '@/models/game';
import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import { UseGameBySlugProps } from './props';

export function useGameBySlug(options?: UseGameBySlugProps) {
  const { slug, refetchOnMount = true } = options ?? {};
  const queryKey: [GamesQueryKeys.BySlug, GameBySlugRequestDto] = [
    GamesQueryKeys.BySlug,
    {
      slug: slug as string,
    },
  ];

  const { data, isPending, error, refetch, fetchStatus, isError } = useQuery<
    Game,
    AxiosError<GenericError>,
    Game,
    [GamesQueryKeys.BySlug, GameBySlugRequestDto]
  >({
    queryKey,
    queryFn: fetchGameBySLug,
    enabled: !!slug,
    staleTime: 20 * 1000,
    refetchOnMount,
    refetchOnWindowFocus: false,
  });

  return {
    game: data,
    isLoading: isPending && fetchStatus !== 'idle',
    error,
    isError,
    refetch,
  };
}
