import Link from '@/components/core/Link';
import { CarouselHeaderProps } from './props';
import styles from './styles.module.scss';
import { TransWithDefault } from '@starsoft/common/components';
import useTranslation from 'next-translate/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/lib/store';
import { AnyAction, Dispatch } from 'redux';
import { toggleSearch } from '@/lib/store/casino/actions';

export default function CarouselHeader<T>({
  icon,
  label,
  redirect,
  dragLeft,
  dragRight,
  secondaryStyles,
  color,
  filterComponent,
  useTransComponent,
  customIcon,
}: CarouselHeaderProps<T>) {
  const { t } = useTranslation('common');
  const dispatch: Dispatch<AnyAction> = useDispatch();
  const openSearch: boolean = useSelector(
    (state: RootState) => state.casino.openSearch,
  );

  function onClick() {
    if (!openSearch) {
      return;
    }

    dispatch(toggleSearch());
  }

  return (
    <div className={styles.container}>
      <span
        className={`${styles.container__label} ${color ? styles['container__label--custom-color'] : ''} ${!!filterComponent ? styles['container__label--filter'] : ''}`}
        style={{
          //@ts-ignore
          '--custom-color': color,
        }}
      >
        {icon && !customIcon && <i className={icon} style={{ color }} />}
        {customIcon && customIcon}
        {useTransComponent ? <TransWithDefault i18nKey={label} /> : label}
        {filterComponent ? filterComponent : null}
      </span>

      <div
        className={`${styles.container__row} ${filterComponent ? styles['container__row--hidden'] : ''}`}
      >
        {redirect && (
          <Link
            className={styles['container__label-link']}
            href={redirect}
            onClick={onClick}
          >
            {t('view_all')}
            <i className="fa-solid fa-chevron-right" />
          </Link>
        )}

        <div className={styles['container__hide-mobile']}>
          <button
            className={`${styles.container__button} ${secondaryStyles ? styles['container__button--secondary'] : ''}`}
            onClick={dragLeft}
          >
            <i className="fa-solid fa-chevron-left" />
          </button>
          <button
            className={`${styles.container__button} ${secondaryStyles ? styles['container__button--secondary'] : ''}`}
            onClick={dragRight}
          >
            <i className="fa-solid fa-chevron-right" />
          </button>
        </div>
      </div>
    </div>
  );
}
