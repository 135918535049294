import { QueryFunctionContext } from '@tanstack/react-query';
import { GamesQueryKeys } from '../../queryKeys';
import { GameBySlugRequestDto } from '@/services/dto/games/game-by-slug-request.dto';
import baseAPI from '@/api';
import { Game } from '@/models/game';

export async function fetchGameBySLug({
  queryKey,
}: QueryFunctionContext<[GamesQueryKeys.BySlug, GameBySlugRequestDto]>) {
  const { slug } = queryKey[1];

  const response = await baseAPI.get<Game>('/games/by-slug', {
    params: {
      slug,
    },
  });

  return response.data;
}
