import { GamesQueryKeys } from '../../queryKeys';
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import { fetchGamesByProvider } from './service';
import { useMemo } from 'react';
import { UseGamesByProviderProps } from './props';
import { GamesByProviderRequestDto } from '@/services/dto/games/games-by-provider-request.dto';
import {
  useInfiniteQueryCallbacks,
  useInfiniteQueryReset,
} from '@starsoft/common/hooks';
import { PaginatedResponse } from '@package/models';
import { Game } from '@/models/game';

export function useGamesByProvider({
  enabled = true,
  limit = 20,
  query,
  providerSlug,
}: UseGamesByProviderProps) {
  const params: GamesByProviderRequestDto = {
    limit,
    query,
    providerSlug,
  };
  const queryKey: [GamesQueryKeys.ByProvider, GamesByProviderRequestDto] = [
    GamesQueryKeys.ByProvider,
    params,
  ];

  const { getNextPageParam, getPreviousPageParam } =
    useInfiniteQueryCallbacks();
  useInfiniteQueryReset(queryKey);

  const { data, isPending, ...infinityQuery } = useInfiniteQuery<
    PaginatedResponse<Game>,
    AxiosError<GenericError>,
    InfiniteData<PaginatedResponse<Game>>,
    [GamesQueryKeys.ByProvider, GamesByProviderRequestDto]
  >({
    queryKey,
    queryFn: fetchGamesByProvider,
    getNextPageParam,
    getPreviousPageParam,
    initialPageParam: 1,
    enabled,
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const formattedData = useMemo(
    () => data?.pages?.map(page => page.data)?.flat(),
    [data],
  );

  return {
    ...infinityQuery,
    isLoading: isPending,
    games: formattedData,
    metadata: data?.pages?.[0]?.metadata,
    error: infinityQuery.error as AxiosError<GenericError>,
  };
}
