import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import GamesBaseSection from '..';
import { useGames } from '@/api/games';
import { GameTagFilter } from '@/models/games/game-tag-filter.enum';
import { RecommendedSectionProps } from './props';

function RecommendedSection({ isFallbacking }: RecommendedSectionProps) {
  const { games, error, isLoading, refetch } = useGames({
    tag: GameTagFilter.Recommended,
    limit: 20,
  });

  return (
    <GamesBaseSection
      data={games ?? []}
      isLoading={isLoading || Boolean(isFallbacking)}
      link={`/games/tag/${GameTagFilter.Recommended}`}
      error={error as AxiosError<GenericError>}
      refetch={refetch}
      label="recommended_label"
      icon="fa-solid fa-star"
    />
  );
}

export default RecommendedSection;
