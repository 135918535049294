import { PaginatedRequest } from '@/models/pagination/paginated-request';
import { UseTopPayoutsQueryKey } from './props';
import { GamesQueryKeys } from '../../queryKeys';
import {
  useInfiniteQueryCallbacks,
  useInfiniteQueryReset,
} from '@starsoft/common/hooks';
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { Game } from '@/models/game';
import { GenericError } from '@/models/generic-error';
import { AxiosError } from 'axios';
import { fetchTopPayout } from './service';
import { useMemo } from 'react';

export function useTopPayouts() {
  const params: PaginatedRequest = {
    limit: 20,
  };
  const queryKey: UseTopPayoutsQueryKey = [GamesQueryKeys.TopPayout, params];

  const { getNextPageParam, getPreviousPageParam } =
    useInfiniteQueryCallbacks();
  useInfiniteQueryReset(queryKey);

  const { data, fetchStatus, ...infinityQuery } = useInfiniteQuery<
    PaginatedResponse<Game>,
    AxiosError<GenericError>,
    InfiniteData<PaginatedResponse<Game>>,
    UseTopPayoutsQueryKey
  >({
    queryKey,
    initialPageParam: 1,
    queryFn: fetchTopPayout,
    getNextPageParam,
    getPreviousPageParam,
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const formattedData = useMemo(
    () => data?.pages?.map(page => page?.data)?.flat(),
    [data],
  );

  return {
    ...infinityQuery,
    games: formattedData,
    isPending: infinityQuery.isPending && fetchStatus !== 'idle',
  };
}
