import styles from './styles.module.scss';
import { carouselGamesTabs } from './options';
import CarouselsGameRenderer from './Renderer';
import { useState } from 'react';
import { GameTagFilter } from '@/models/games/game-tag-filter.enum';
import { Nullable } from '@starsoft/common/interfaces';
import { Tabs } from '@starsoft/common/components';

export default function GamesSectionCarousel() {
  const [tab, setTab] = useState<Nullable<GameTagFilter>>(
    carouselGamesTabs[0].value,
  );

  return (
    <div className={styles.container}>
      <Tabs
        setTab={setTab}
        invertColors
        useContainer
        isSmall
        currentTab={tab}
        tabs={carouselGamesTabs}
      />
      <CarouselsGameRenderer tag={tab} />
    </div>
  );
}
