import { QueryFunctionContext } from '@tanstack/react-query';
import { GamesQueryKeys } from '../../queryKeys';
import baseAPI from '@/api';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { Game } from '@/models/game';
import { GamesByProviderRequestDto } from '@/services/dto/games/games-by-provider-request.dto';

export async function fetchGamesByProvider({
  pageParam = 1,
  queryKey,
}: QueryFunctionContext<
  [GamesQueryKeys.ByProvider, GamesByProviderRequestDto]
>) {
  const { query, limit, providerSlug } = queryKey[1];

  const response = await baseAPI.get<PaginatedResponse<Game>>(
    '/games/by-provider',
    {
      params: {
        page: pageParam,
        query,
        limit,
        providerSlug,
      },
    },
  );

  return response.data;
}
