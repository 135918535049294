import { QueryFunctionContext } from '@tanstack/react-query';
import { UseTopPayoutsQueryKey } from './props';
import { PaginatedRequest } from '@/models/pagination/paginated-request';
import { AxiosResponse } from 'axios';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { Game } from '@/models/game';
import baseAPI from '@/api';

export async function fetchTopPayout({
  queryKey,
  pageParam,
}: QueryFunctionContext<UseTopPayoutsQueryKey>): Promise<
  PaginatedResponse<Game>
> {
  const { limit }: PaginatedRequest = queryKey[1];

  const response: AxiosResponse<PaginatedResponse<Game>> = await baseAPI.get<
    PaginatedResponse<Game>
  >('/games/top-payout', {
    params: {
      limit,
      page: pageParam,
    },
  });

  return response.data;
}
