import { GameTagFilter } from '@/models/games/game-tag-filter.enum';
import { Tab } from '@starsoft/common/src/components/Tabs/Tab/props';

export const carouselGamesTabs: Tab<GameTagFilter>[] = [
  {
    label: 'lobby_label',
    value: null,
    icon: 'fa-solid fa-house fa-swap-opacity',
  },
  {
    label: 'originals_label',
    value: GameTagFilter.Originals,
    icon: 'fa-solid fa-fire',
  },
  {
    label: 'slots_label',
    value: GameTagFilter.Slots,
    icon: 'fa-solid fa-cherries fa-swap-opacity',
  },
  {
    label: 'fortune_label',
    value: GameTagFilter.Fortune,
    icon: 'fa-solid fa-spade fa-swap-opacity',
  },
  {
    label: 'roulette_label',
    value: GameTagFilter.Roulette,
    icon: 'fa-sharp fa-solid fa-card-diamond',
  },
  {
    label: 'tab_live_casino',
    value: GameTagFilter.LiveCasino,
    icon: 'fa-solid fa-tv-retro fa-swap-opacity',
  },
];
