import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import GamesBaseSection from '..';
import { useGames } from '@/api/games';
import { GameTagFilter } from '@/models/games/game-tag-filter.enum';

function OriginalsSection() {
  const { games, error, isLoading, refetch } = useGames({
    tag: GameTagFilter.Originals,
    limit: 20,
  });

  return (
    <GamesBaseSection
      data={games ?? []}
      isLoading={isLoading}
      link={`/games/tag/${GameTagFilter.Originals}`}
      error={error as AxiosError<GenericError>}
      refetch={refetch}
      label="originals_label"
      icon="fa-solid fa-dice"
    />
  );
}

export default OriginalsSection;
