import { GamesRequestDto } from '@/services/dto/games/games-request.dto';
import { GamesQueryKeys } from '../../queryKeys';
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import { fetchGames } from './service';
import { useMemo } from 'react';
import {
  useInfiniteQueryCallbacks,
  useInfiniteQueryReset,
} from '@starsoft/common/hooks';
import { UseGamesProps, UseGamesQueryKey } from './props';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { Game } from '@/models/game';

export function useGames(options?: UseGamesProps) {
  const { enabled = true, limit = 50, query, tag, providerIds } = options ?? {};
  const params: GamesRequestDto = {
    limit,
    query: !!query ? query : undefined,
    tag,
    providerIds: (providerIds?.length ?? 0) > 0 ? providerIds : undefined,
  };
  const queryKey: UseGamesQueryKey = [GamesQueryKeys.Primary, params];

  const { getNextPageParam, getPreviousPageParam } =
    useInfiniteQueryCallbacks();
  useInfiniteQueryReset(queryKey);

  const { data, fetchStatus, ...infinityQuery } = useInfiniteQuery<
    PaginatedResponse<Game>,
    AxiosError<GenericError>,
    InfiniteData<PaginatedResponse<Game>>,
    UseGamesQueryKey
  >({
    queryKey,
    initialPageParam: 1,
    queryFn: fetchGames,
    getNextPageParam,
    getPreviousPageParam,
    enabled,
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const formattedData = useMemo(
    () => data?.pages?.map(page => page?.data)?.flat(),
    [data],
  );

  return {
    ...infinityQuery,
    games: formattedData,
    metadata: data?.pages?.[0]?.metadata,
    isLoading: infinityQuery.isPending && fetchStatus !== 'idle',
    error: infinityQuery.error as AxiosError<GenericError>,
  };
}
