import useTranslation from 'next-translate/useTranslation';
import { ProviderSectionProps } from './props';
import { GameProviderId } from '@/enums/gameProviderId';
import styles from './styles.module.scss';
import { Image, ErrorCard, NoDataComponent } from '@starsoft/common/components';
import { useCallback, useMemo } from 'react';
import Carousel from '@/components/Home/Carousel';
import { useRouter } from 'next/router';

export default function ProvidersSection({
  data,
  isLoading,
  error,
  refetch,
}: ProviderSectionProps) {
  const { t } = useTranslation('common');
  const { push } = useRouter();

  const handleTargetProvider = useCallback(
    (slug: string) => {
      push(`/games/providers/${slug}`);
    },
    [push],
  );

  const Providers = useMemo(
    () =>
      data?.map(provider => (
        <div
          className={styles.card}
          key={`banner-provider-${provider.id}`}
          onClick={() => handleTargetProvider(provider.slug as string)}
        >
          <Image
            className={`${styles.card__image} ${provider?.externalId !== GameProviderId.Originals ? styles['card__image--filtered'] : ''}`}
            src={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/game-providers/${provider?.image}`}
            alt={provider?.name}
            secondarySkeleton
          />
        </div>
      )),
    [data, handleTargetProvider],
  );

  const Skeletons = useMemo(
    () =>
      Array.from({ length: 20 }).map((_, i) => (
        <div
          className={styles.skeleton}
          key={`small-banner-provider-skeleton-${i}`}
        />
      )),
    [],
  );

  if (error) {
    return <ErrorCard error={error} refetch={refetch} />;
  }

  return (
    <Carousel
      icon="fa-solid fa-joystick"
      label={t('tab_providers')}
      redirect="/games/providers"
    >
      {Providers.length == 0 && !isLoading && (
        <NoDataComponent isSmall isSecondaryStyles />
      )}
      {Providers}
      {isLoading && Skeletons}
    </Carousel>
  );
}
